.font-icon {
  font-family: serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-decoration: none;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
  Introduced in Internet Explorer 10.
  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'GRAD' 0;
  font-optical-sizing: auto;
}

/* you need to also include the outlined as well for this to work at all*/
.material-symbols-and-filled {
  font-variation-settings: 'FILL' 1,
  'GRAD' 0;
}

.font-icon {
  font-family: 'ecode', 'Material Icons', serif;
}

.faded.material-icons, .disabled .material-icons, .faded.font-icon, .disabled .font-icon {
  opacity: 0.54;
}

.material-icons.md-16, .font-icon.md-16 {
  font-size: 16px;
}

.material-icons.md-18, .font-icon.md-18 {
  font-size: 18px;
}

.material-icons.md-20, .font-icon.md-20 {
  font-size: 20px;
}

.material-icons.md-22, .font-icon.md-22 {
  font-size: 22px;
}

.material-icons.md-24, .font-icon.md-24 {
  font-size: 24px;
}

.material-icons.md-26, .font-icon.md-26 {
  font-size: 26px;
}

.material-icons.md-28, .font-icon.md-28 {
  font-size: 28px;
}

.material-icons.md-30, .font-icon.md-30 {
  font-size: 30px;
}

.material-icons.md-32, .font-icon.md-32 {
  font-size: 32px;
}

.material-icons.md-34, .font-icon.md-34 {
  font-size: 34px;
}

.material-icons.md-36, .font-icon.md-36 {
  font-size: 36px;
}

.material-icons.md-38, .font-icon.md-38 {
  font-size: 38px;
}

.material-icons.md-40, .font-icon.md-40 {
  font-size: 40px;
}

.material-icons.md-42, .font-icon.md-42 {
  font-size: 42px;
}

.material-icons.md-44, .font-icon.md-44 {
  font-size: 44px;
}

.material-icons.md-46, .font-icon.md-46 {
  font-size: 46px;
}

.material-icons.md-48, .font-icon.md-48 {
  font-size: 48px;
}

.material-icons.md-50, .font-icon.md-50 {
  font-size: 50px;
}

.material-icons.md-52, .font-icon.md-52 {
  font-size: 52px;
}

.material-icons.md-54, .font-icon.md-54 {
  font-size: 54px;
}

.material-icons.md-56, .font-icon.md-56 {
  font-size: 56px;
}

.material-icons.md-58, .font-icon.md-58 {
  font-size: 58px;
}

.material-icons.md-60, .font-icon.md-60 {
  font-size: 60px;
}

.material-icons.md-62, .font-icon.md-62 {
  font-size: 62px;
}

.material-icons.md-64, .font-icon.md-64 {
  font-size: 64px;
}

.material-icons.md-66, .font-icon.md-66 {
  font-size: 66px;
}

.material-icons.md-68, .font-icon.md-68 {
  font-size: 68px;
}

.material-icons.md-70, .font-icon.md-70 {
  font-size: 70px;
}